// src/plugins/analytics/index.js

import VueGtag from 'vue-gtag'

export default {
install(Vue, options) {
  // Error tracking rate limiting
  const errorCache = new Set()
  const ERROR_CACHE_TIMEOUT = 1000 * 60 * 5 // 5 minutes

  // Analytics configuration per environment
  const GA_CONFIG = {
    local: {
      measurementId: 'G-ZTQLMDTSZN',
      appName: 'Deliver - Local',
      debugMode: true
    },
    stage: {
      measurementId: 'G-2HXLQJ774K',
      appName: 'Deliver - Stage',
      debugMode: true
    },
    production: {
      measurementId: 'G-42XJLRH3GR',
      appName: 'Deliver - Production',
      debugMode: false
    }
  }

  // Constants for event categories (aligned with GA4 standards)
  const EVENT_CATEGORIES = {
    USER: 'user',
    ENGAGEMENT: 'engagement',
    ERROR: 'error',
    NAVIGATION: 'navigation'
  }

  // Validate environment configuration
  const validateEnvironmentConfig = (config) => {
    if (!config?.measurementId) {
      console.error('Invalid GA4 configuration: missing measurementId')
      return false
    }
    return true
  }

  // Safe GA4 call wrapper with auth check
  const safeGtagCall = (callback, ignoreAuth = false) => {
    const user = options.store.state.User.user
    if (!ignoreAuth && !user?.isAuthed) {
      console.debug('GA4 call blocked - user not authenticated')
      return
    }

    try {
      callback()
    } catch (error) {
      console.error('GA4 tracking error:', error)
      trackError(error, { 
        type: 'gtag_error',
        function: callback.name || 'anonymous'
      })
    }
  }

  // Determine current environment
  const getCurrentEnvironment = () => {
    const hostname = window.location.hostname
    
    if (/localhost/.test(hostname)) {
      return 'local'
    } else if (/stage\.deliverdooh\.com/.test(hostname)) {
      return 'stage'
    } else if (/(deliver|verify)\.dooh\.com/.test(hostname)) {
      return 'production'
    }
    
    console.warn('Environment could not be determined, defaulting to local')
    return 'local'
  }

  // Get and validate current environment configuration
  const currentEnv = getCurrentEnvironment()
  const envConfig = GA_CONFIG[currentEnv]
  if (!validateEnvironmentConfig(envConfig)) {
    console.error('Analytics initialization aborted due to invalid configuration')
    return
  }

  // Error tracking utility with rate limiting
  const trackError = (error, context = {}) => {
    const errorKey = `${error.message}:${error.stack}`
    if (errorCache.has(errorKey)) return

    errorCache.add(errorKey)
    setTimeout(() => errorCache.delete(errorKey), ERROR_CACHE_TIMEOUT)

    safeGtagCall(() => {
      window.gtag('event', 'exception', {
        description: error.message || 'Unknown error',
        fatal: true,
        error_name: error.name,
        error_stack: error.stack,
        error_context: JSON.stringify(context)
      })
    })
  }

  // Performance tracking utility
  const trackTiming = (category, variable, value) => {
    safeGtagCall(() => {
      window.gtag('event', 'timing_complete', {
        event_category: category,
        event_label: variable,
        value: Math.round(value)
      })
    })
  }

  // User properties management
  const setUserProperties = (user) => {
    if (!user?.id) {
      console.warn('Invalid user data provided to setUserProperties')
      return
    }

    safeGtagCall(() => {
      // Set user_id first
      window.gtag('config', envConfig.measurementId, {
        user_id: user.id
      })

      // Set user properties
      window.gtag('set', 'user_properties', {
        role_id: user.roleId || null,
        role_name: user.roleName || null,
        stakeholder_id: user.stakeholderId || null,
        stakeholder_name: user.stakeholderName || null
      })

      // Send login event with GA4 recommended parameters
      window.gtag('event', 'login', {
        method: 'internal',
        user_id: user.id
      })
    })
  }

  // Clear user properties function
  const clearUserProperties = (oldUser) => {
    // Use ignoreAuth=true to ensure these calls go through during logout
    safeGtagCall(() => {
      // Send logout event first while we still have user context
      if (oldUser?.id) {
        window.gtag('event', 'logout', {
          method: 'internal',
          user_id: oldUser.id
        })
      }

      // Clear user_id
      window.gtag('config', envConfig.measurementId, {
        user_id: null
      })

      // Remove all user properties by setting them to null
      window.gtag('set', 'user_properties', {
        user_id: null,
        role_id: null,
        role_name: null,
        stakeholder_id: null,
        stakeholder_name: null
      })
    }, true) // Pass ignoreAuth=true
  }

  // Helper function to check if path should be tracked
  const shouldTrackPath = (path, fullPath) => {
    const rootPaths = ['', '/', '/#/']
    return !rootPaths.includes(path) && !rootPaths.includes(fullPath)
  }

  // Page view tracking with improved error handling
  const sendPageView = (to, from, campaignData = null) => {
    // Skip tracking for root or empty paths
    if (!shouldTrackPath(to.path, to.fullPath)) {
      console.debug('Skipping page view tracking for root/empty path:', to.path)
      return
    }

    const startTime = performance.now()

    const pageData = {
      page_title: to.name,
      page_path: to.fullPath,
      page_location: window.location.href
    }

    if (isCampaignRoute(to) && campaignData) {
      try {
        const campaignTitle = `${campaignData.name} - ${to.name}`
        pageData.page_title = campaignTitle
        pageData.campaign_id = campaignData.id
        pageData.campaign_name = campaignData.name
        pageData.campaign_status = campaignData.workflowStatus?.as
        pageData.view_type = to.query.tab || 'default'

        // If this is a tab change within the same campaign, send tab_change event instead of page_view
        if (from && 
            to.path === from.path && 
            to.query.cid === from.query.cid && 
            to.query.tab !== from.query.tab) {
          safeGtagCall(() => {
            window.gtag('event', 'tab_change', {
              event_category: EVENT_CATEGORIES.ENGAGEMENT,
              page_title: campaignTitle,
              campaign_id: campaignData.id,
              campaign_name: campaignData.name,
              campaign_status: campaignData.workflowStatus?.as,
              previous_tab: from.query.tab || 'default',
              new_tab: to.query.tab || 'default'
            })
          })
          return
        }
      } catch (error) {
        console.error('Error processing campaign data:', error)
        trackError(error, { 
          type: 'campaign_data_error',
          campaign: campaignData
        })
      }
    }

    safeGtagCall(() => {
      window.gtag('event', 'page_view', pageData)
      
      const loadTime = performance.now() - startTime
      trackTiming('Page Load', to.name, loadTime)
    })
  }

  // Helper functions
  const getCampaignIdFromUrl = () => {
    try {
      const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
      return urlParams.get('cid')
    } catch {
      console.warn('Error parsing campaign ID from URL')
      return null
    }
  }

  const isCampaignRoute = (route) => {
    return route.path.includes('/campaigns/campaign')
  }

  const waitForCampaign = (store, campaignId, maxWaitTime = 10000) => {
    return new Promise((resolve, reject) => {
      const currentCampaign = store.state.CurrentCampaign.campaign
      if (currentCampaign?.id === parseInt(campaignId)) {
        resolve(currentCampaign)
        return
      }

      const unwatch = store.watch(
        state => state.CurrentCampaign.campaign,
        newCampaign => {
          if (newCampaign?.id === parseInt(campaignId)) {
            unwatch()
            resolve(newCampaign)
          }
        },
        { immediate: true }
      )

      if (maxWaitTime) {
        setTimeout(() => {
          unwatch()
          reject(new Error(`Campaign ${campaignId} not found after ${maxWaitTime}ms`))
        }, maxWaitTime)
      }
    })
  }

  // Initialize Vue GTM
  Vue.use(VueGtag, {
    config: {
      id: envConfig.measurementId,
      params: {
        send_page_view: false,
        debug_mode: envConfig.debugMode
      }
    },
    appName: envConfig.appName,
    pageTrackerEnabled: false,
    enabled: true,
    bootstrap: true,
    onReady: () => {
      Vue.nextTick(() => {
        const user = options.store.state.User.user
        if (user && user.id) {
          setUserProperties(user)
        }
      })
    }
  }, options.router)

  // Enhanced event tracking methods
  Vue.prototype.$track = {
    userAction(action, details = {}) {
      safeGtagCall(() => {
        window.gtag('event', action, {
          event_category: EVENT_CATEGORIES.USER,
          ...details
        })
      })
    },

    campaignAction(action, campaign, details = {}) {
      if (!campaign || !isCampaignRoute(options.router.currentRoute)) return

      const urlCampaignId = getCampaignIdFromUrl()
      if (campaign.id === parseInt(urlCampaignId)) {
        safeGtagCall(() => {
          window.gtag('event', action, {
            event_category: EVENT_CATEGORIES.ENGAGEMENT,
            campaign_id: campaign.id,
            campaign_name: campaign.name,
            campaign_status: campaign.workflowStatus?.as,
            ...details
          })
        })
      }
    },

    systemEvent(action, details = {}) {
      safeGtagCall(() => {
        window.gtag('event', action, {
          event_category: EVENT_CATEGORIES.SYSTEM,
          ...details
        })
      })
    }
  }

  // Performance monitoring with cleanup
  let performanceListener = null
  if (typeof window !== 'undefined' && window.performance) {
    performanceListener = setTimeout(() => {
      const navigationTiming = performance.getEntriesByType('navigation')[0]
      if (navigationTiming) {
        trackTiming('Navigation', 'DOM Complete', navigationTiming.domComplete)
        trackTiming('Navigation', 'Load Complete', navigationTiming.loadEventEnd)
      }
    }, 0)
  }

  // Global error tracking
  if (typeof window !== 'undefined') {
    window.addEventListener('error', (event) => {
      trackError(event.error, {
        type: 'window_error',
        filename: event.filename,
        lineno: event.lineno,
        colno: event.colno
      })
    })

    window.addEventListener('unhandledrejection', (event) => {
      trackError(event.reason, {
        type: 'unhandled_promise_rejection'
      })
    })
  }

  // Campaign tracking state
  let currentCampaign = null

  // Watch for user changes
  options.store.watch(
    (state) => state.User.user,
    (newUser, oldUser) => {
      if (newUser?.id && (!oldUser || newUser.id !== oldUser.id)) {
        setUserProperties(newUser)
      } else if (oldUser?.isAuthed && !newUser?.isAuthed) {
        clearUserProperties(oldUser)
      }
    },
    { 
      deep: true,
      immediate: true
    }
  )

  // Router tracking
  options.router.afterEach((to, from) => {
    if (!isCampaignRoute(to)) {
      currentCampaign = null
      sendPageView(to, from)
      return
    }

    const urlCampaignId = getCampaignIdFromUrl()

    if (urlCampaignId) {
      waitForCampaign(options.store, urlCampaignId)
        .then(campaign => {
          currentCampaign = campaign
          sendPageView(to, from, currentCampaign)
        })
        .catch(() => {
          sendPageView(to, from)
        })
    } else {
      sendPageView(to, from)
    }
  })

  // Cleanup on app destruction
  if (typeof window !== 'undefined') {
    window.addEventListener('unload', () => {
      if (performanceListener) {
        clearTimeout(performanceListener)
      }
    })
  }
}
}
